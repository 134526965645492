import React from 'react';
import { t } from '../utils/labels';
import { RetailerDetailQuery } from '../graphql/query/RetailerDetailQuery';
import { EntityDetailFetcher } from '../components/EntityDetailFetcher';
import typeIntro from '../static/introspection/Retailer.json';
import { ManagedByUserDetail } from './ManagedByUserDetail';
import { ManagedByUserEdit } from './ManagedByUserEdit';
import { ManagedByUserCreate } from './ManagedByUserCreate';
import { ENTITY } from '../utils/entities';
import gql from 'graphql-tag';
import { UserData } from '@food/auth';
import { pluralEntityLabel } from '../utils/misc';
import { refactorInfo } from '../utils/form';
import { BrandEdit } from './BrandEdit';
import { BrandDetail } from './BrandDetail';
import { BrandCreate } from './BrandCreate';
import { GoodEdit } from './GoodEdit';
import { GoodDetail } from './GoodDetail';

export const RetailerDetail = ({ id }) => (
	<UserData>
		{({ isInternal }) => (
			<EntityDetailFetcher
				query={RetailerDetailQuery}
				entityName={ENTITY.RETAILER}
				id={id}
				typeIntro={typeIntro}
				clonable={false}
				fields={[
					isInternal ? 'sfId' : null,
					'name',
					'logoMedia',
					'type',
					'billingName',
					'description',
					'descriptionIta',
					'descriptionLong',
					'descriptionLongIta',
					'pecEmail',
					'vatNumber',
					'website',
					'Country',
					'StateProvince',
					'postalCode',
					'city',
					'address1',
					'address2',
					'lat',
					'lng',
					'managedByUsers',
					'brands',
					'goods'
				]}
				transformer={(data, entity) => {
					data.fields = data.fields.map((f) => {
						switch (f.name) {
							case 'managedByUsers':

								if (f.type !== 'EntityList') {
									refactorInfo(f, 'EntityList');
									return f;
								}

								f.props.headers = ['User', 'capabilities'];
								f.editComponent = ManagedByUserEdit;
								f.detailComponent = ManagedByUserDetail;
								f.addComponent = ManagedByUserCreate;
								f.tab = 1;
								f.props.fragment = gql`
									fragment Fragment on ManagedByUser {
										User {
											id
											name
										}
										capabilities
									}
								`;

								break;

							case 'brands':
								if (f.type !== 'EntityList') {
									refactorInfo(f, 'EntityList');
									break;
								}
								f.tab = 2;
								f.editComponent = BrandEdit;
								f.detailComponent = BrandDetail;
								f.addComponent = BrandCreate;
								f.props.headers = [
									'name',
									'pricePositioning',
									'website',
								];
								f.props.fragment = gql`
									fragment Fragment on Brand {
										name
										pricePositioning
										website
									}
								`;

								break;

							case 'goods':
								if (f.type !== 'EntityList') {
									refactorInfo(f, 'EntityList');
									break;
								}
								f.tab = 3;
								f.editComponent = GoodEdit;
								f.detailComponent = GoodDetail;
								//f.addComponent = GoodCreate;
								f.props.headers = [
									'name',
									'nameIta'
								];
								f.props.paginated = true;
								f.props.query = gql`
									query RetailerGoodQuery (
										$first: Int,
										$cursor: String
									) {
										goods (first: $first, after: $cursor, filter: { Retailer: { id: "${entity.id}"}  }) {
											edges {
												node {
													id
													name
													nameIta
												}
											}
											total
											pageInfo {
												hasNextPage
												hasPreviousPage
											}
										}
									}
								`;
								f.props.extractor = (data) => data.goods;
								break;
							default:
								break;
						}
						return f;
					});
					return data;
				}}
				tabs={[
					t('generic data'),
					t([ENTITY.RETAILER, ENTITY.MANAGED_BY_USER, 'label'].join('/')),
					t([ENTITY.RETAILER, 'brands', 'label'].join('/')),
					t(pluralEntityLabel(ENTITY.GOOD)),
				]}
				cols={[['col-sm-7', 'col-sm-5'], ['col-sm-12'], ['col-sm-12'], ['col-sm-12']]}
			/>
		)}
	</UserData>
);
