import React, { useCallback, useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { t } from '../../utils/labels';
import {
	FormFeedback,
	FormGroup,
	FormText,
	Label,
	Col,
	Button,
} from 'reactstrap';
import { entity2String } from '../../utils/toString';
import { AsyncChoiceFieldType, FieldComponent } from '../../types/form';
import * as R from 'ramda';
import { getLocalError } from '../../utils/formErrors';
import { FormContext } from '../../utils/context/form';
import { getDefaultNodeData } from '../../utils/graphql';
import { registerClass } from '@food/css-manager';
import { IChoice } from '../../utils/misc';
import { ViewContext } from '../../utils/EntityViewContext';
import { EntityModalState, EntityModalStateEnum } from '../EntityModalState';
const matchingRegex = [
	/^https:\/\/www\.italianfood\.net\/[a-z]+\/([a-zA-Z0-9-_~]+)/i,
	/^http(s)?:\/\/(((www\.)?hub\.italianfood\.net)|(localhost:[0-9]+))\/[a-z]+\/([a-zA-Z0-9-_~]+)/i,
];

const styleClass = registerClass(
	() => `
	display: flex;
	
	& > *:first-child {
		flex: 1 auto;
	}
`,
);

export const AsyncChoicesField: React.FC<FieldComponent<
	AsyncChoiceFieldType
>> = ({ field, changeHandler, path }) => {
	const formContext = useContext(FormContext);

	const {
		name,
		asyncQuery,
		entityToLabel,
		value,
		compact,
		label,
		disabled,
		hidden,
		single,
		description,
		helpText,
		additionalHelpComponent,
		entityType,
		addComponent,
	} = field;
	const [tmpText, setTmpText] = useState('');
	const [state, setState] = useState<{
		searchText: string;
		loading: boolean;
		options: ReadonlyArray<IChoice>;
	}>({
		searchText: '',
		loading: true,
		options: [],
	});
	const { loading, options, searchText } = state;
	const entityToChoice = useCallback(
		(entity: any) => ({
			value: entity,
			label: entityToLabel
				? entityToLabel(entity)
				: entity2String(entity.__typename, entity),
		}),
		[],
	);

	useEffect(() => {
		asyncQuery(searchText).then((queryResult) => {
			let options: any[] = queryResult.entities.map(entityToChoice);

			if (queryResult.remaining > 0) {
				options = options.concat({
					label: queryResult.remaining + ' ' + t`hidden items`,
					disabled: true,
				});
			}

			setState({
				loading: false,
				searchText,
				options,
			});
		});
	}, [searchText, asyncQuery]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			const match = matchingRegex
				.map((r) => r.exec(tmpText))
				.filter(
					(res) => res !== null && Array.isArray(res) && res.length > 1,
				)[0];

			if (match) {
				// entro in modalita' url
				const slug = match[match.length - 1];
				const parts = slug.split('-');
				const id = parts[parts.length - 1];

				setState({
					loading: true,
					searchText,
					options: [],
				});

				getDefaultNodeData(field.entityType, id).then((res) => {
					if (
						res.data &&
						res.data.node &&
						res.data.node.__typename === entityType
					) {
						const entity = res.data.node;
						setState({
							loading: false,
							searchText: '',
							options: [entityToChoice(entity)],
						});
					} else {
						setState({
							loading: false,
							searchText,
							options: [],
						});
					}
				});
			} else {
				// faccio una ricerca per nome sull'entita'
				if (tmpText !== searchText) {
					setState({
						loading: true,
						searchText: tmpText,
						options: [],
					});
				}
			}
		}, 500);
		return () => clearTimeout(timeout);
	}, [tmpText]);



	const className = compact ? 'filter col-sm-3' : hidden ? 'hidden' : '';
	// non ha senso una gestione piu' raffinata degli error che comprenderebbe filtrarli secondo il path
	// poiche' in questo campo ho gia' filtrato il necessario e non ho campi figli
	const error = formContext ? getLocalError(path, formContext.errors) : null;

	const CreateC = field.addComponent;

	return (
		<FormGroup className={className} row={!compact}>
			{!compact && <Label sm={3}>{label}: </Label>}
			<Col sm={compact ? 12 : 9} className={compact ? 'full-width' : ''}>
				<div className={styleClass}>
					<Select
						classNamePrefix={name + '-async-select '}
						className={'input-border' + (error ? ' is-invalid' : '')}
						inputValue={tmpText}
						isDisabled={disabled}
						value={value}
						isLoading={loading}
						options={options}
						isMulti={!single}
						isClearable={true}
						onInputChange={setTmpText}
						onChange={(val) => {
							changeHandler(val, path);
						}}
						placeholder={compact ? label : t`Start typing to search`}
						noResultsText={t`no results text`}
						getOptionValue={R.path(['value', 'id'])}
						filterOption={R.always(true)}
					/>
					{addComponent && (
						<EntityModalState>
							{(modalState, changeState) => (
								<>
									<ViewContext.Provider
										value={{
											type: 'modal',
											mode: modalState,
											changeState,
										}}
									>
										<CreateC
											onSubmit={(entity) => {
												changeState(EntityModalStateEnum.Closed);
												changeHandler(entityToChoice(entity), path);
											}}
										/>
									</ViewContext.Provider>
									<Button
										className={'margin-left'}
										color={'danger'}
										onClick={() =>
											changeState(EntityModalStateEnum.Create)
										}
									>
										<i className={'fa fa-plus'} /> {t`create`}
									</Button>
								</>
							)}
						</EntityModalState>
					)}
				</div>
				{error && !compact && (
					<FormFeedback invalid>
						{t(error.message + '/choices', error.message)}
					</FormFeedback>
				)}
				{!compact && (
					<FormText color="muted">
						{description.readonly && (
							<div className="usability-note">{t`readonly field description`}</div>
						)}
						{helpText}
						{additionalHelpComponent}
					</FormText>
				)}
			</Col>
		</FormGroup>
	);
};
