/**
 * Questo e' il componente standard per la visualizzazione di dettagli a tutto schermo (primo livello di gerarchia).
 * In realta' ci sono altri due piccoli componenti utilizzati solo internamente; sono considerabili parti del componente
 * principale.
 */

import React, { useMemo } from 'react';
import { PageTitle } from '../components/PageTitle';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { t } from '../utils/labels';
import { getParameters } from '../utils/routeHistory';
import { ACTIONS, generateUrl } from '../utils/urls';
import { HistoryLink } from '../components/historyLink';
import { canView } from '../utils/viewRoles';
import { ENTITY } from '../utils/entities';
import { UserData } from '@food/auth';
import { IField } from '../types/form';
import { Tabs } from '../components/Tabs';
import { renderFieldRO } from '../utils/form';
import { DeleteEntityModal } from '../components/DeleteEntityModal';
import { EntityDetailViewProps } from '../components/EntityDetailViewSwitch';

export interface IEntityDetailAction {
	icon: string;
	label: string;
	color: string;
	handler: () => void;
}

export interface IFieldROProps<T extends IField> {
	field: T;
	modal?: boolean;
}

const BackButton: React.FC<{ entityType: ENTITY }> = ({ entityType }) => {
	const basePath = generateUrl(entityType, ACTIONS.LIST);
	const params = getParameters(basePath);

	return (
		<Link to={{ pathname: basePath, search: params }}>
			<Button color="secondary" className="margin-right">
				<i className="fa fa-list-ul" /> {t`Back`}
			</Button>
		</Link>
	);
};

const EditButton: React.FC<{
	entityType: ENTITY;
	id: string;
	customEditUrl?: (id: string) => string;
}> = ({ entityType, id, customEditUrl }) => (
	<Link
		to={
			customEditUrl
				? customEditUrl(id)
				: generateUrl(entityType, ACTIONS.EDIT, id)
		}
	>
		<Button color="success" className="margin-right" data-action="edit">
			<i className="fa fa-edit" /> {t`edit`}
		</Button>
	</Link>
);

export const EntityDetailView: React.FC<EntityDetailViewProps> = ({
	entityName,
	clonable,
	permissions,
	id,
	footer,
	footerHTML,
	customActions,
	customActionComponents,
	title,
	breadcrumbs,
	entity,
	pre,
	post,
	hideHistory,
	showActions = true,
	data: { fields },
	tabs = ['generic data'],
	cols = tabs.map(() => ['col-sm-12']),
	customEditUrl,
}) => {
	const history = useHistory();
	const location = useLocation();
	// devo usare "useMemo" per evitare che il controllo avvenga a ogni re-render: non e' un effetto collaterale
	// (useEffect), non e' uno state (useState), ma mi serve che il controllo venga fatto solo al mount del componente,
	// quindi useMemo mi sembra legittimo (anche se un po' strano)
	const initialTab = useMemo(() => {
		return location.hash?.replace('#', '');
	}, [location.hash]);

	const cloneButton =
		clonable && permissions.canCreate ? (
			<Link to={generateUrl(entityName, ACTIONS.CLONE, id)}>
				<Button color="info" className="margin-right" data-action={'clone'}>
					<i className="mdi mdi-arrange-send-backward" /> {t`Clone`}
				</Button>
			</Link>
		) : null;

	return (
		<DeleteEntityModal
			entityId={entity.id}
			entityName={entityName}
			onDelete={() => {
				history.push(generateUrl(entityName, ACTIONS.LIST));
			}}
			onFailedDelete={(e) => {
				// TODO Implementare la gestione degli errori nella cancellazione entita' nelle pagine principali
				console.log(e)
			}}
		>
			{(openDeleteModal) => {
				return (
					<div className="edit-entity-view">
						<UserData>
							{(user) => (
								<>
									<PageTitle title={title} breadcrumbs={breadcrumbs} />
									{pre && pre(entity)}
									<Tabs tabs={tabs} initialTab={initialTab}>
										{(activeTab, tabIndex) => {
											const renderDeleteButton = permissions.canDelete ? (
												<Button
													color="danger"
													className="margin-right"
													onClick={openDeleteModal}
												>
													<i className="fa fa-trash" /> {t`delete`}
												</Button>
											) : null;
											return (
												<>
													{showActions && tabIndex === 0 && (
														<Row className="margin-left margin-bottom">
															<BackButton
																entityType={entityName}
															/>
															{permissions.canEdit && (
																<EditButton
																	entityType={entityName}
																	id={id}
																	customEditUrl={customEditUrl}
																/>
															)}
															{renderDeleteButton}
															{cloneButton}
															{!hideHistory &&
																canView(
																	user,
																	ENTITY.OBJECT_HISTORY,
																) && (
																	<HistoryLink
																		id={entity.id}
																	/>
																)}
															{customActions &&
																customActions.map((action) => (
																	<Button
																		className="margin-right"
																		key={action.label}
																		color={
																			action.color ||
																			'success'
																		}
																		onClick={action.handler}
																	>
																		<i
																			className={
																				'fa ' + action.icon
																			}
																		/>{' '}
																		{action.label}
																	</Button>
																))}
															{customActionComponents &&
																customActionComponents(entity)}
														</Row>
													)}
													<Row>
														{cols[tabIndex].map((cClass, i) => (
															<Col
																key={i}
																className={cClass}
																sm={12}
															>
																{fields
																	.filter(
																		(f) => f.tab === tabIndex,
																	)
																	.filter((f) => f.col === i)
																	.map((f) =>
																		renderFieldRO(f),
																	)}
															</Col>
														))}
													</Row>
													{showActions && tabIndex === 0 && (
														<Row className="margin-left margin-top">
															<BackButton
																entityType={entityName}
															/>
															{permissions.canEdit && (
																<EditButton
																	entityType={entityName}
																	id={id}
																/>
															)}
															{renderDeleteButton}
															{cloneButton}
															{customActions &&
																customActions.map((action) => (
																	<Button
																		className="margin-right"
																		key={action.label}
																		color={
																			action.color ||
																			'success'
																		}
																		onClick={action.handler}
																	>
																		<i
																			className={
																				'fa ' + action.icon
																			}
																		/>{' '}
																		{action.label}
																	</Button>
																))}
														</Row>
													)}
												</>
											);
										}}
									</Tabs>

									{post && post}

									{(footer || footerHTML) && (
										<Row>
											<Col sm={10}>
												<Card>
													<CardBody>
														{footer}
														<div
															dangerouslySetInnerHTML={{
																__html: footerHTML,
															}}
														/>
													</CardBody>
												</Card>
											</Col>
										</Row>
									)}
								</>
							)}
						</UserData>
					</div>
				);
			}}
		</DeleteEntityModal>
	);
};
